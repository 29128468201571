<template>
  <tj-dialog
    v-model="visible"
    :title="title"
    @close="onClose"
  >
    <el-form ref="formRef" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="故障名称：" prop="name">
        <el-input v-model.trim="form.name"></el-input>
      </el-form-item>
      <el-form-item label="设备：">
        <el-cascader
          v-model="device"
          :options="deviceList"
          :props="deviceProps"
          filterable
          class="wf"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="关联商品：">
        <el-cascader
          v-model="goodsIds"
          :options="goods"
          :props="{ multiple: true }"
          class="wf"
        ></el-cascader>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import {
  groupBy,
  map,
  first,
  find,
  compact,
} from 'lodash';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);

const defaultFaultInfo = {
  id: 0,
  name: '',
  brand_id: 0,
  device_model_id: 0,
  goods_ids: [],
};

const form = ref({ ...defaultFaultInfo });
const formRef = ref();

const rules = {
  name: { required: true, message: '故障名称不能为空' },
};

const title = computed(() => {
  if (form.value.id) {
    return '编辑故障';
  }
  return '添加故障';
});

const device = computed({
  get() {
    const {
      brand_id: brandId,
      device_model_id: modelId,
    } = form.value;
    if (modelId) {
      return [brandId, modelId];
    }
    return null;
  },
  set([brandId, modelId]) {
    form.value.brand_id = brandId;
    form.value.device_model_id = modelId;
  },
});
const deviceProps = {
  label: 'name',
  value: 'id',
};
const deviceList = ref([]);
function getDeviceList() {
  request.get('/admin/device/deviceModel').then((data) => {
    deviceList.value = map(groupBy(data, 'brand_id'), (children, id) => {
      const { brand_name: name } = first(children);
      return {
        id,
        name,
        children,
      };
    });
  });
}

const goods = ref([]);
const originGoods = ref([]);
const goodsIds = computed({
  get() {
    if (originGoods.value.length) {
      return compact(map(form.value.goods_ids, (id) => {
        const finded = find(originGoods.value, { id });
        if (finded) {
          return [finded.goods_category_id, id];
        }
        return null;
      }));
    }
    return [];
  },
  set(val) {
    form.value.goods_ids = map(val, ([, id]) => id);
  },
});
function getGoods() {
  request.get('/admin/goods/goods').then((data) => {
    originGoods.value = data;
    goods.value = map(groupBy(data, 'goods_category_id'), (list, cid) => {
      const { goods_category_name: cn } = first(list);
      return {
        label: cn,
        value: Number(cid),
        children: map(list, (item) => ({
          label: item.name,
          value: item.id,
        })),
      };
    });
  });
}

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  getDeviceList();
  getGoods();
  if (id) {
    request.get(`/admin/fault/fault/${id}`).then((data) => {
      form.value = data;
      form.value.goods_ids = map(data.goods, ({ id: goodId }) => goodId);
    });
  } else {
    form.value = { ...defaultFaultInfo };
  }
  nextTick(() => {
    formRef.value.clearValidate();
  });
});
function onClose() {
  form.value = { ...defaultFaultInfo };
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      const { id } = form.value;
      if (id) {
        request.patch(`/admin/fault/fault/${id}`, form.value).then(() => {
          emit('submit');
          close();
        });
      } else {
        request.post('/admin/fault/fault', form.value).then(() => {
          emit('submit');
          close();
        });
      }
    }
  });
}

defineExpose({
  open,
});
</script>
